import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'

import Layout from '../../../components/layout'
import { rhythm } from '../../../utils/typography'
import { themeStyles, colors, metrics, presets } from '../../../utils/theme'
import PageTitle from '../../../components/PageTitle'
import CustomLink from '../../../components/CustomLink'

const BlogItem = ({ node }) => {
  return (
    <CustomLink
      style={{ boxShadow: 'none', textAlign: 'center', textDecoration: 'none' }}
      to={node.fields.slug}
    >
      <div
        css={{
          backgroundImage: `url(${node.frontmatter.image})`,
          minWidth: '400px',
          minHeight: '225px',
          marginBottom: rhythm(1),
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      ></div>
      <h3 css={{ maxWidth: '400px', marginBottom: rhythm(1 / 2) }}>
        {node.frontmatter.title}
      </h3>
      <p css={{ color: colors.brightBlue }}>{node.frontmatter.previewText}</p>
    </CustomLink>
  )
}

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const posts = get(this, 'props.data.allMarkdownRemark.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <div css={themeStyles.contentWidth}>
          <PageTitle title="Collins Blog" bannerKey={'RESOURCES'} />
          <div css={themeStyles.textPadding}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                width: '100%',
              }}
            >
              {posts.map(({ node }) => {
                return <BlogItem node={node} />
              })}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            image
          }
        }
      }
    }
  }
`
